<template lang="pug">
.season
  v-container
    v-row(align="end")
      v-col.offset-md-1(sm="6", md="5")
        high-chart-polar(
          v-if="polarObject.averagePercentList.length > 0",
          :personalList="polarObject.personalList",
          :personalPercentList="polarObject.personalPercentList",
          :averageList="polarObject.averageList",
          :averagePercentList="polarObject.averagePercentList",
          :bestList="polarObject.bestList",
          :personalSeriesTitle="'個人'"
        )
      v-col.offset-1.offset-sm-0.offset-md-0.offset-lg-1.mt-8.mt-sm-0(
        cols="10",
        sm="5",
        md="5",
        lg="4",
        xl="3"
      )
        position-field(
          @selectPosition="positionClick",
          :positionList="shotPositionList",
          :mainColor="'#487AA4'",
          :subColor="'#96B9DC'"
        )
    v-row.table_row.mt-15
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            top-head(category="Season")
          thead.bottom_head
            bottom-head(category="Season")

          tbody
            tr(v-for="(player, index) in playerData", :key="index")
              sticky-column.bdl.bdr2(
                style="width: 120px; min-width: 120px",
                :offset="0",
                :title="player.info.split_info.name"
              )

              td {{ player.info.record_matches }}
              td.bdr {{ getMinutes(player.box.avg_secs) }}
              td(
                @click="dataClick(player, 'pts', '得分')",
                :class="[player.box.avg_pts > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_pts.toFixed(1) }}
              td(
                @click="dataClick(player, 'reb', '籃板')",
                :class="[player.box.avg_reb > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_reb.toFixed(1) }}
              td(
                @click="dataClick(player, 'ast', '助攻')",
                :class="[player.box.avg_ast > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_ast.toFixed(1) }}
              td(
                @click="dataClick(player, 'stl', '抄截')",
                :class="[player.box.avg_stl > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_stl.toFixed(1) }}
              td.bdr(
                @click="dataClick(player, 'blk', '阻攻')",
                :class="[player.box.avg_blk > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_blk.toFixed(1) }}

              td(
                @click="dataClick(player, 'fg_m', '投籃命中')",
                :class="[player.box.avg_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_fgm.toFixed(1) }}
              td(
                @click="dataClick(player, 'fg_a', '投籃出手')",
                :class="[player.box.avg_fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_fga.toFixed(1) }}
              td.bdr {{ (player.box.avg_fg_percent * 100).toFixed(1) + '%' }}

              td(
                @click="dataClick(player, 'two_pts_m', '兩分命中')",
                :class="[player.box.avg_two_pt_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_two_pt_fgm.toFixed(1) }}
              td(
                @click="dataClick(player, 'two_pts_a', '兩分出手')",
                :class="[player.box.avg_two_pt_fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_two_pt_fga.toFixed(1) }}
              td.bdr {{ (player.box.avg_two_pt_fg_percent * 100).toFixed(1) + '%' }}
              td(
                @click="dataClick(player, 'three_pts_m', '三分命中')",
                :class="[player.box.avg_three_pt_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_three_pt_fgm.toFixed(1) }}
              td(
                @click="dataClick(player, 'three_pts_a', '三分出手')",
                :class="[player.box.avg_three_pt_fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_three_pt_fga.toFixed(1) }}
              td.bdr {{ (player.box.avg_three_pt_fg_percent * 100).toFixed(1) + '%' }}
              td(
                @click="dataClick(player, 'ft_m', '罰球命中')",
                :class="[player.box.avg_ftm > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_ftm.toFixed(1) }}
              td(
                @click="dataClick(player, 'ft_a', '罰球出手')",
                :class="[player.box.avg_fta > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_fta.toFixed(1) }}
              td.bdr {{ (player.box.avg_ft_percent * 100).toFixed(1) + '%' }}
              td(
                @click="dataClick(player, 'off_reb', '進攻籃板')",
                :class="[player.box.avg_off_reb > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_off_reb.toFixed(1) }}
              td.bdr(
                @click="dataClick(player, 'def_reb', '防守籃板')",
                :class="[player.box.avg_def_reb > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_def_reb.toFixed(1) }}
              td(
                @click="dataClick(player, 'tov', '失誤')",
                :class="[player.box.avg_tov > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_tov.toFixed(1) }}
              td(
                @click="dataClick(player, 'pf', '犯規')",
                :class="[player.box.avg_pf > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_pf.toFixed(1) }}
              td(:class="[player.box.avg_plus_minus > 0 ? '' : 'gray_out']") {{ player.box.avg_plus_minus.toFixed(1) }}
              td(:class="[player.box.avg_eff > 0 ? '' : 'gray_out']") {{ player.box.avg_eff.toFixed(1) }}
              td.bdr(:class="[player.box.avg_at_ratio > 0 ? '' : 'gray_out']") {{ player.box.avg_at_ratio.toFixed(1) }}

    v-row.table_row.mt-15(v-show="playerSeasonDateData.length > 0")
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            top-head(category="Season")
          thead.bottom_head
            bottom-head(category="Season")

          tbody
            box(
              v-for="(player, index) in playerSeasonDateData",
              :key="index",
              :item="player",
              :title="player.info.split_info.name"
            )

    v-row.table_row.mt-15
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            top-head(category="Season")
          thead.bottom_head
            bottom-head(category="Season")

          tbody
            box(
              v-for="(player, index) in playerSeasonRecentData",
              :key="index",
              :item="player",
              :title="player.info.split == 'LAST_3' ? '近三場' : '近五場'"
            )

    v-row.table_row.mt-15(v-show="playerSeasonMonthData.length > 0")
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            top-head(category="Season")
          thead.bottom_head
            bottom-head(category="Season")

          tbody
            box(
              v-for="(player, index) in playerSeasonMonthData",
              :key="index",
              :item="player",
              :title="player.info.split_info.name"
            )

    v-row.table_row.mt-15
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            top-head(category="Season")
          thead.bottom_head
            bottom-head(category="Season")

          tbody
            box(
              v-for="(player, index) in playerSeasonWinLoseData",
              :key="index",
              :item="player",
              :title="player.info.split == 'win' ? '勝' : '負'"
            )

    v-row.table_row.mt-15
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            top-head(category="Season")
          thead.bottom_head
            bottom-head(category="Season")

          tbody
            box(
              v-for="(player, index) in playerSeasonVSTeamData",
              :key="index",
              :item="player",
              :title="'vs. ' + player.info.split_info.name"
            )

  v-dialog.position-relative(
    v-model="isDialogShow",
    style="background: white; height: 100%; width: 810px"
  )
    .close_btn_container
      v-icon.close_btn(@click="isDialogShow = false", large, color="black") mdi-close
    v-container.position-relative.content_container(ref="content")
      v-row(justify="center")
        .uvideo
          youtube(
            ref="youtube",
            @ready="onYTReady",
            @ended="onYTEnd",
            resize,
            fitParent,
            nocookie,
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
          )

          .yt_cover(v-if="coverYT")
            v-btn.play_all.cover(
              @click="playAllClick",
              rounded="",
              color="#487aa4",
              dark,
              v-ripple="false"
            )
              v-icon(left="")
                | mdi-play
              | 播放全部
      v-row(justify="space-between")
        .reference
          //- span.d-block 影片出處：
          //-   a.text_primary(
          //-     href="https://www.youtube.com/c/ssutv/videos",
          //-     target="_blank"
          //-   ) https://www.youtube.com/c/ssutv/videos
          //- span 影片版權皆歸中華民國大專院校體育總會及轉播單位所有
        v-btn.play_all(
          @click="playAllClick",
          rounded="",
          color="#487aa4",
          dark,
          v-ripple="false"
        )
          v-icon(left="")
            | mdi-play
          | 播放全部
      v-row
        span.player_name_team {{ dialogName }} | {{ dialogTeam }}
        .play_table
          .play_head
            .head.play_play
            .head.play_time 時間
            .head.play_player 球員
            .head.play_team 隊伍
            .head.play_event 事件
            .head.play_feedback 問題回報
          .play_content
            .play_item(
              :class="{ selected: currentPlayIndex == index }",
              v-for="(play, index) in playList",
              :key="index"
            )
              .content.play_play
                v-icon.play(x-small, color="white") mdi-play
              .content.play_time {{ play.video_start_at }}
              .content.play_player {{ dialogName }}
              .content.play_team {{ dialogTeam }}
              .content.play_event {{ dialogEvent }}
              .content.play_feedback
                v-icon(small) mdi-alert-octagon
              .play_btn(@click="onPlayItemClick(play, index)")
              .feedback_btn(@click="onFeedbackBtnClick(play)")
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import PositionField from "@/components/common/PositionField";
import HighChartPolar from "@/components/common/HighChartPolar";
import Box from "@/components/common/Box";
import BottomHead from "@/components/common/Box/BottomHead";
import TopHead from "@/components/common/Box/TopHead";
import { getPlayerSeasonData, getPlayerRankingData } from "@/api/player";
import { getPlayList } from "@/api/play";
import moment from "moment";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";

export default {
  name: "LeaguePlayerSeason",
  components: {
    StickyColumn,
    PositionField,
    HighChartPolar,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
    Box,
    BottomHead,
    TopHead,
  },
  data() {
    return {
      season: this.$route.params.season,
      playerID: this.$route.params.id,
      seasonPlayerId: 0,
      playerData: [],
      bestPlayerRankList: [],
      polarObject: {
        averageList: [],
        personalList: [],
        averagePercentList: [],
        personalPercentList: [],
        bestList: [],
      },

      playerSeasonVSTeamData: [],
      playerSeasonWinLoseData: [],
      playerSeasonDateData: [],
      playerSeasonRecentData: [],
      playerSeasonMonthData: [],

      windowWidth: 0,
      head: null,
      stickyNum: 2,

      shotPositionList: [],

      isDialogShow: false,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",
      playList: [],

      feedBackPlay: null,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      dialogFeedbackShow: false,
      dialogLoginShow: false,
    };
  },
  watch: {
    "$route.params.season"() {
      this.season = this.$route.params.season;

      this.playerData = [];
      this.bestPlayerRankList = [];
      this.polarObject = {
        averageList: [],
        personalList: [],
        averagePercentList: [],
        personalPercentList: [],
        bestList: [],
      };
      this.playerSeasonVSTeamData = [];
      this.playerSeasonWinLoseData = [];
      this.playerSeasonDateData = [];
      this.playerSeasonRecentData = [];
      this.playerSeasonMonthData = [];
      this.shotPositionList = [];

      this.initData();
    },
    isDialogShow() {
      if (!this.isDialogShow) {
        this.$refs.youtube.player.stopVideo();
        this.dialogName = "";
        this.dialogTeam = "";
        this.dialogEvent = "";
        this.isPlayAll = false;
        this.playAllIndex = 0;
        this.currentPlayIndex = null;
        this.playList = [];
        this.coverYT = true;
      }
    },
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getPlayerRankingData();
      await this.getPlayerSeasonData();
    },
    async getPlayerRankingData() {
      let temp = {
        season_id: this.season,
        part: "info,ranking",
        ranking: 1,
      };
      await getPlayerRankingData(temp).then((response) => {
        this.bestPlayerRankList = response.data;
      });
    },
    async getPlayerSeasonData() {
      let temp = {
        season_id: this.season,
        part: "info,ranking,box,shot_position",
        player_id: this.playerID,
      };
      await getPlayerSeasonData(temp).then((response) => {
        this.playerData = response.data.summary;
        this.playerSeasonDateData = response.data.season_type;
        this.playerSeasonMonthData = response.data.month;
        this.playerSeasonRecentData = response.data.recent;
        this.playerSeasonWinLoseData = response.data.win_lose;
        this.playerSeasonVSTeamData = response.data.vs_team;
        this.shotPositionList = this.getShotPositionList(
          this.playerData.at(-1).shot_position
        );
        this.seasonPlayerId = this.playerData.at(-1).info.season_player_id;
        this.getPolarObject();
      });
    },
    getPolarObject() {
      this.polarObject.personalList.push(
        Number(this.playerData[0].ranking.avg_pts.value.toFixed(1))
      );
      this.polarObject.personalList.push(
        Number(this.playerData[0].ranking.avg_reb.value.toFixed(1))
      );
      this.polarObject.personalList.push(
        Number(this.playerData[0].ranking.avg_ast.value.toFixed(1))
      );
      this.polarObject.personalList.push(
        Number(
          (this.playerData[0].ranking.avg_ft_percent.value * 100).toFixed(1)
        )
      );
      this.polarObject.personalList.push(
        Number(
          (
            this.playerData[0].ranking.avg_three_pt_fg_percent.value * 100
          ).toFixed(1)
        )
      );
      this.polarObject.personalList.push(
        Number(
          (
            this.playerData[0].ranking.avg_two_pt_fg_percent.value * 100
          ).toFixed(1)
        )
      );

      this.polarObject.bestList.push(
        Number(this.bestPlayerRankList.avg_pts[0].ranking.value.toFixed(1))
      );
      this.polarObject.bestList.push(
        Number(this.bestPlayerRankList.avg_reb[0].ranking.value.toFixed(1))
      );
      this.polarObject.bestList.push(
        Number(this.bestPlayerRankList.avg_ast[0].ranking.value.toFixed(1))
      );
      this.polarObject.bestList.push(
        Number(
          (
            this.bestPlayerRankList.avg_ft_percent[0].ranking.value * 100
          ).toFixed(1)
        )
      );
      this.polarObject.bestList.push(
        Number(
          (
            this.bestPlayerRankList.avg_three_pt_fg_percent[0].ranking.value *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.bestList.push(
        Number(
          (
            this.bestPlayerRankList.avg_two_pt_fg_percent[0].ranking.value * 100
          ).toFixed(1)
        )
      );

      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_pts.value /
              this.bestPlayerRankList.avg_pts[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_reb.value /
              this.bestPlayerRankList.avg_reb[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_ast.value /
              this.bestPlayerRankList.avg_ast[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_ft_percent.value /
              this.bestPlayerRankList.avg_ft_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_three_pt_fg_percent.value /
              this.bestPlayerRankList.avg_three_pt_fg_percent[0].ranking
                .value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_two_pt_fg_percent.value /
              this.bestPlayerRankList.avg_two_pt_fg_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );

      this.polarObject.averageList.push(
        Number(this.playerData[0].ranking.avg_pts.avg.toFixed(1))
      );
      this.polarObject.averageList.push(
        Number(this.playerData[0].ranking.avg_reb.avg.toFixed(1))
      );
      this.polarObject.averageList.push(
        Number(this.playerData[0].ranking.avg_ast.avg.toFixed(1))
      );
      this.polarObject.averageList.push(
        Number((this.playerData[0].ranking.avg_ft_percent.avg * 100).toFixed(1))
      );
      this.polarObject.averageList.push(
        Number(
          (
            this.playerData[0].ranking.avg_three_pt_fg_percent.avg * 100
          ).toFixed(1)
        )
      );
      this.polarObject.averageList.push(
        Number(
          (this.playerData[0].ranking.avg_two_pt_fg_percent.avg * 100).toFixed(
            1
          )
        )
      );

      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_pts.avg /
              this.bestPlayerRankList.avg_pts[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_reb.avg /
              this.bestPlayerRankList.avg_reb[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_ast.avg /
              this.bestPlayerRankList.avg_ast[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_ft_percent.avg /
              this.bestPlayerRankList.avg_ft_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_three_pt_fg_percent.avg /
              this.bestPlayerRankList.avg_three_pt_fg_percent[0].ranking
                .value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.playerData[0].ranking.avg_two_pt_fg_percent.avg /
              this.bestPlayerRankList.avg_two_pt_fg_percent[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
    },
    getShotPositionList(shotPosition) {
      let tempList = [];

      let PAINT = {
        name: "PAINT",
        fga: shotPosition.paint.fga,
        fgm: shotPosition.paint.fgm,
        percent: (shotPosition.paint.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(PAINT);

      let TOP_OF_THE_ARC_2_PTS = {
        name: "TOP_OF_THE_ARC_2_PTS",
        fga: shotPosition.top_2pt.fga,
        fgm: shotPosition.top_2pt.fgm,
        percent: (shotPosition.top_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(TOP_OF_THE_ARC_2_PTS);

      let TOP_OF_THE_ARC_3_PTS = {
        name: "TOP_OF_THE_ARC_3_PTS",
        fga: shotPosition.top_3pt.fga,
        fgm: shotPosition.top_3pt.fgm,
        percent: (shotPosition.top_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(TOP_OF_THE_ARC_3_PTS);

      let LEFT_BASELINE_2_PTS = {
        name: "LEFT_BASELINE_2_PTS",
        fga: shotPosition.left_baseline_2pt.fga,
        fgm: shotPosition.left_baseline_2pt.fgm,
        percent:
          (shotPosition.left_baseline_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_BASELINE_2_PTS);

      let RIGHT_BASELINE_2_PTS = {
        name: "RIGHT_BASELINE_2_PTS",
        fga: shotPosition.right_baseline_2pt.fga,
        fgm: shotPosition.right_baseline_2pt.fgm,
        percent:
          (shotPosition.right_baseline_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_BASELINE_2_PTS);

      let LEFT_WING_2_PTS = {
        name: "LEFT_WING_2_PTS",
        fga: shotPosition.left_wing_2pt.fga,
        fgm: shotPosition.left_wing_2pt.fgm,
        percent: (shotPosition.left_wing_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_WING_2_PTS);

      let RIGHT_WING_2_PTS = {
        name: "RIGHT_WING_2_PTS",
        fga: shotPosition.right_wing_2pt.fga,
        fgm: shotPosition.right_wing_2pt.fgm,
        percent:
          (shotPosition.right_wing_2pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_WING_2_PTS);

      let LEFT_BASELINE_3_PTS = {
        name: "LEFT_BASELINE_3_PTS",
        fga: shotPosition.left_baseline_3pt.fga,
        fgm: shotPosition.left_baseline_3pt.fgm,
        percent:
          (shotPosition.left_baseline_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_BASELINE_3_PTS);

      let RIGHT_BASELINE_3_PTS = {
        name: "RIGHT_BASELINE_3_PTS",
        fga: shotPosition.right_baseline_3pt.fga,
        fgm: shotPosition.right_baseline_3pt.fgm,
        percent:
          (shotPosition.right_baseline_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_BASELINE_3_PTS);

      let LEFT_WING_3_PTS = {
        name: "LEFT_WING_3_PTS",
        fga: shotPosition.left_wing_3pt.fga,
        fgm: shotPosition.left_wing_3pt.fgm,
        percent: (shotPosition.left_wing_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_WING_3_PTS);

      let RIGHT_WING_3_PTS = {
        name: "RIGHT_WING_3_PTS",
        fga: shotPosition.right_wing_3pt.fga,
        fgm: shotPosition.right_wing_3pt.fgm,
        percent:
          (shotPosition.right_wing_3pt.fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_WING_3_PTS);

      return tempList;
    },
    formatDate(date) {
      return moment(date).format("YYYY/MM/DD");
    },
    dataClick(player, stat, event) {
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = player.info.name;
      this.dialogTeam = player.info.team_name;
      this.dialogEvent = event;
      this.getPlayList(stat);
    },
    getPlayList(stat) {
      this.showLoadingDialog("載入中...");

      let params = {
        season_player_id: this.seasonPlayerId,
        group_by: "PLAYER",
        stat: stat,
      };

      let data = {};

      getPlayList(params, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    positionClick(positionData) {
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = this.playerData[0].info.name;
      this.dialogTeam = this.playerData[0].info.team_name;
      this.dialogEvent = positionData.dialogEvent;
      this.getPlayDataByPosition(positionData.position);
    },
    getPlayDataByPosition(position) {
      this.showLoadingDialog("載入中...");
      let query_param = {
        group_by: "PLAYER",
        season_player_id: this.seasonPlayerId,
      };

      let data = { shot_position: position };

      getPlayList(query_param, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    playAllClick() {
      this.coverYT = false;
      this.isPlayAll = true;
      this.playAllIndex = 0;
      this.currentPlayIndex = this.playAllIndex;
      if (this.playList && this.playList.length > 0) {
        this.playVideo(this.playList[this.playAllIndex]);
      }
    },
    onYTReady() {
      if (this.playList && this.playList.length > 0) {
        this.YTPlayer.cueVideoById({
          videoId: this.playList[0].game__youtube_ids__0,
        });
      }
    },
    onYTEnd(e) {
      if (this.isPlayAll && e.getVideoLoadedFraction() > 0) {
        if (this.playList && this.playList.length > 0) {
          if (this.playAllIndex + 1 < this.playList.length) {
            this.playAllIndex = this.playAllIndex + 1;

            this.currentPlayIndex = this.playAllIndex;

            this.playVideo(this.playList[this.playAllIndex]);
          } else {
            this.isPlayAll = false;
            this.playAllIndex = 0;
          }
        }
      }
    },
    onPlayItemClick(play, index) {
      this.coverYT = false;
      this.currentPlayIndex = index;
      this.playVideo(play);
    },
    onFeedbackBtnClick(play) {
      play.league = this.league;
      play.team = this.dialogTeam;
      this.feedBackPlay = play;
      this.showFeedbackDialog();
      // if (!this.$store.state.user.accessToken) {
      //   this.showLoginDialog();
      // } else {
      //   this.showFeedbackDialog();
      // }
    },
    playVideo(play) {
      this.scrollToTop();
      var startSeconds =
        play.result == "DRAW FOUL"
          ? moment.duration(play.video_end_at).asSeconds()
          : moment.duration(play.video_start_at).asSeconds();
      this.YTPlayer.loadVideoById({
        videoId: play.game__youtube_ids__0,
        startSeconds: startSeconds - play.video_play_duration,
        endSeconds: moment.duration(play.video_start_at).asSeconds() + 2,
      });
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/common/dialog_play_video";

input,
textarea,
select {
  background: #ffffff;
  border: 1px solid #487aa4;
}

input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/arrow_bottom_s.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
}

@media (max-width: 959px) {
  .img_circle {
    width: 80px;
    height: 80px;
    margin-bottom: 5px;
  }

  .player_name,
  .bar_title.type,
  .player_data span.type,
  .membership_team .team {
    font-size: 16px;
  }
}
</style>
